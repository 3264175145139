import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import Header from './Header';

import './styles.scss';

function Layout({ title, subtitle }) {
  return (
    <div className="container">
      <Header title={title} subtitle={subtitle} />
      <main className="mainContent"><Outlet /></main>
    </div>
  );
}

Layout.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
export default Layout;
