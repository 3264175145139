import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function Form({ children, onSubmit }) {
  const onFormSubmit = useCallback(
    (event) => {
      event.preventDefault();
      onSubmit(new FormData(event.target));
    },
    [children, onSubmit],
  );

  return (
    <form className="form" onSubmit={onFormSubmit}>
      {children}
    </form>
  );
}

Form.propTypes = {
  children: PropTypes.any,
  onSubmit: PropTypes.func,
};

export default Form;
