/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

import { useRecoilState } from 'recoil';
import { identitySelector } from '@recoil/auth';

/*
* Component
*/
function Header({ title, subtitle }) {
  const [identity, setIdentity] = useRecoilState(identitySelector);
  
  function logout() {
    if (confirm('Confirmez-vous vouloir vous déconnecter ?')) {
      setIdentity(null);
    }
  }

  return (
    <header className="mainHeader">
      {location.pathname !== '/login' && <button type="button" className="logoutButton" onClick={logout}>Déconnexion</button>}
      <div className="logo">
        <div>Le rituel de</div>
      </div>
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
    </header>
  );
}
Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
Header.defaultProps = {
  title: '',
  subtitle: '',
};

/*
 * Export
 */
export default Header;
