import Layout from 'src/components/Layout';
import LoginPage from 'src/pages/LoginPage';
import NotFound from 'src/pages/NotFound/index';
import RituelPage from 'src/pages/RituelPage/index';
import StartPage from 'src/pages/StartPage/index';
import RituelsListPage from 'src/pages/RituelsListPage/index';
import { Navigate } from 'react-router-dom';
import ResultRituel from 'src/pages/ResultRituel';

export default [
  {
    children: [{ path: '/', breadcrumb: 'Accueil', element: <Navigate replace to="/courses" /> }],
  },
  {
    element: <Layout title="Connexion" subtitle="C'est le moment de s'identifier" />,
    children: [
      {
        path: '/login',
        breadcrumb: 'Connexion',
        element: <LoginPage />,
      },
    ],
  },
  {
    element: <Layout title="Rituels" subtitle="La liste des rituels auxquels tu t'es inscrit" />,
    children: [
      {
        path: '/courses',
        breadcrumb: 'Rituels',
        element: <RituelsListPage />,
      },
    ],
  },
  {
    element: <Layout title="Le rituel d'initiation" subtitle="QCM pour tester tes compétences" />,
    children: [
      {
        path: '/start/:course',
        breadcrumb: 'Débuter le test',
        element: <StartPage />,
      },
    ],
  },
  {
    element: <Layout title="Le rituel d'initiation" subtitle="QCM pour tester tes compétences" />,
    children: [
      {
        path: '/rituel/:course',
        breadcrumb: 'Rituel',
        element: <RituelPage />,
      },
    ],
  },
  {
    element: <Layout title="Résultat de ton rituel" subtitle="Alors ? On a fini son rituel ?" />,
    children: [
      {
        path: '/result/:course',
        breadcrumb: 'Résultat du rituel',
        element: <ResultRituel />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
