import React, { useEffect, useMemo, useState } from 'react';
import ApiCaller from 'src/commons/ApiCaller';
import { useError } from 'src/commons/MessagesProvider';
import Skeleton from 'react-loading-skeleton';

import './styles.scss';
import RituelCard from './components/RituelCard/index';

function RituelsListPage() {
  const [loading, setLoading] = useState(false);
  const [rituels, setRituels] = useState([]);
  const showError = useError();

  useEffect(() => {
    setLoading(true);
    ApiCaller.makeRequest('GET', '/user/rituel')
      .then((data) => {
        setRituels(data.rituels);
      })
      .catch(showError)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const endedRituels = useMemo(() => rituels.filter((rituel) => rituel?.dateEnd), [rituels]);
  const waitingRituels = useMemo(() => rituels.filter((rituel) => !rituel?.dateEnd), [rituels]);

  return (
    <div className="rituels-list-page">
      <div className="text-container">
        
        <br />
        <p>
          Pour déterminer si tu as toutes les qualités d'un développeur, nous allons titiller ta culture en informatique,
          tes aptitudes en logique, ta connaissance de la formation et tes notions d'anglais.
        </p>
        <em>
          Petit tuyau : pour être dans les meilleures conditions pour le test, on te conseille de le passer depuis un ordinateur.
        </em>
      </div>
      {loading ? (
        <Skeleton count={5} />
      ) : (
        
        <div className="rituels-list-container">
        
          {waitingRituels.length && (
          <div className="rituels-list rituels-list--waiting">
            <h2>Rituels en attente</h2>
            <div className="rituels-list-cards">
              {waitingRituels.map((rituel) => (
                <RituelCard key={rituel?.course} rituel={rituel} />
              ))}
            </div>
          </div>
          ) || null}

          {endedRituels.length && (
          <div className="rituels-list rituels-list--ended">
            <h2>Rituels terminés</h2>
            <div className="rituels-list-cards">
              {endedRituels.map((rituel) => (
                <RituelCard key={rituel?.course} rituel={rituel} />
              ))}
            </div>
          </div>
          ) || null}
        </div>
      )}
    </div>
  );
}

export default React.memo(RituelsListPage);
