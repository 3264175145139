import React, { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { createRoot } from 'react-dom/client';

import App from 'src/components/App';
import AuthProvider from 'src/commons/AuthProvider';
import MessagesProvider from 'src/commons/MessagesProvider';
import 'react-loading-skeleton/dist/skeleton.css'

import packageJSON from '../package.json';
import ApiCaller from './commons/ApiCaller';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.NODE_ENV,
  release: packageJSON.version,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  normalizeDepth: 10, // Or however deep you want your state context to be.
});

const container = document.querySelector('#root');
const root = createRoot(container);

const jwToken = localStorage.getItem('jwToken');
if (jwToken) {
  ApiCaller.setToken(jwToken);
}

export const Application = (
  <RecoilRoot>
    <BrowserRouter basename="/">
      <MessagesProvider>
        <AuthProvider>
            <Suspense fallback={<h1>Chargement de la page</h1>}>
              <App />
            </Suspense>
        </AuthProvider>
      </MessagesProvider>
    </BrowserRouter>
  </RecoilRoot>
);

root.render(Application);
