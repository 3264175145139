import ExtendableError from 'src/utils/errors/ExtendableError';

/**
 * Class representing an API error.
 * @extends ExtendableError
 */
export default class APIError extends ExtendableError {
  /**
   * Create an API error.
   * @param {String} error - Error error.
   * @param {Number} status - HTTP-Status code of error.
   */
}
