export default [
  'Pour être le plus confort possible, c’est mieux de passer le test sur un ordinateur (portable ou fixe).',
      
  'Généralement, 30 minutes suffisent pour réaliser le Rituel. Le score final tiendra compte de tes réponses mais aussi du temps écoulé. Mais inutile de te mettre une grosse pression : mieux vaut prendre un peu de temps pour bien répondre, plutôt que de tout foirer à la vitesse de la lumière. Après, tu fais comme tu veux...',
      
  'Aucune question n’est obligatoire : ne reste pas bloqué trop longtemps sur la même question.',
      
  'Fais comme chez toi ! Tu peux passer librement aux questions suivantes et revenir en arrière si tu le souhaites. Il suffit de faire défiler la page.',
      
  'Google est ton ami, mais il est vicieux. Tu as le droit de rechercher une information sur Internet mais attention à ne pas le faire trop souvent car cela te fera perdre un temps précieux.',
      
  'Pour information, le Rituel comprend des questions d’informatique, d’anglais, de code, de connaissance de la formation et de logique. On te le dit comme ça : c’est la logique qui prend le plus de temps.',
      
  'Certaines questions sont à choix multiple et d’autres sont ouvertes. Pour gagner du temps, rien ne sert d’écrire d’énormes pavés pour les questions ouvertes.',
      
  'Ce questionnaire est confidentiel et personnel : ne communique à personne le lien vers le Rituel sans l’autorisation écrite de l’école O’clock. Tous les accès au questionnaire sont logués et nominatifs.',
      
  'Si tu utilises un ordinateur portable, pense à le mettre en charge pour éviter de te retrouver en panne de batterie ! Ce serait bête qu’il s’éteigne à la dernière question. Mais quelque part, ça serait drôle aussi…',
      
  'Assure-toi de ne pas être dérangé(e) pendant toute la durée du Rituel : on met son téléphone en silencieux, on coupe Twitter, Facebook et on bâillonne ses animaux de compagnie (pour le poisson rouge, on peut faire une exception).',
];
