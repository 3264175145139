/*
 * Pacakge Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

function Steps({ questions, currentQuestionIndex, questionsPassed, answers, onStepClick }) {
  return (
    <div className="rituelpage-steps Steps">
      {questions.map((question, index) => (
        <button
          type="button"
          className={
              classNames(
                'step', {
                  'step--current': currentQuestionIndex === index,
                  'step--passed': questionsPassed[question?._id] !== undefined,
                  'step--done': answers[question?._id] !== undefined,
                },
              )
            }
          key={`step-${index + 1}`}
          onClick={onStepClick.bind(this, index)}
        >
          {index + 1}
        </button>
      ))}
    </div>
  );
}

Steps.propTypes = {
  questions: PropTypes.array,
  currentQuestionIndex: PropTypes.number,
  questionsPassed: PropTypes.object,
  answers: PropTypes.object,
  onStepClick: PropTypes.func,
};

Steps.defaultProps = {
  questions: [],
  currentQuestionIndex: false,
  questionsPassed: {},
  answers: {},
  onStepClick: () => {},
};

export default Steps;
