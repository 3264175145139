import { useCallback, useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ApiCaller from 'src/commons/ApiCaller';
import Button from 'src/components/Form/Button/index';
import { Input } from 'src/components/Form/index';
import startPhrases from 'src/utils/startPhrases';
import './styles.scss';

function StartPage() {
  const { course } = useParams();
  const navigate = useNavigate();
  const [checkboxesState, setCheckboxesState] = useState(startPhrases.reduce((acc, phrase) => ({ ...acc, [phrase]: false }), {}));

  useEffect(() => {
    ApiCaller.makeRequest('GET', `/user/rituel/${course}`)
      .then(({ dateBegin, dateEnd }) => {
        if (dateBegin && !dateEnd) {
          navigate(`/rituel/${course}`);
        }
        else if (dateEnd) {
          navigate(`/result/${course}`);
        }
      })
      .catch((error) => { console.error(error); });
  }, []);

  const changeCheckboxState = useCallback((phrase, checked) => {
    setCheckboxesState({ ...checkboxesState, [phrase]: checked });
  }, [checkboxesState]);

  return (
    <div className="startpage-container">
      <h1>Avant de te lancer, prends note des consignes suivantes et coche chaque case.</h1>

      <div className="checkboxes">
        {startPhrases.map((phrase) => (
          <Input
            type="checkbox"
            name={phrase}
            label={phrase}
            key={phrase}
            onChange={(event) => {
              changeCheckboxState(phrase, event.target.checked);
            }}
          />
        ))}
      </div>

      <div className="buttons-container">
        <Link to="/courses">
          <Button>Revenir à la liste des rituels</Button>
        </Link>
        <Link to={`/rituel/${course}`}>
          <Button disabled={!Object.values(checkboxesState).reduce((acc, value) => acc && value)}>Démarrer</Button>
        </Link>
      </div>
    </div>
  );
}

export default StartPage;
