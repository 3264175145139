import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { identitySelector } from '@recoil/auth';
import ApiCaller from 'src/commons/ApiCaller';
import { useRecoilState } from 'recoil';
import Button from 'src/components/Form/Button/index';
import Form from 'src/components/Form/form';
import { Input } from 'src/components/Form/index';
import './styles.scss';
import { useError } from 'src/commons/MessagesProvider';

function LoginPage() {
  const showError = useError();

  const [identity, setIdentity] = useRecoilState(identitySelector);
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (identity?.sub) {
      navigate('/courses', { replace: true });
    }
  }, []);

  const handleSubmit = () => {
    ApiCaller.makeRequest('post', '/auth/login', { email, password })
      .then((res) => {
        const { jwToken } = res;
        setIdentity(jwToken);
        navigate('/courses');
      })
      .catch((err) => {
        showError(err.message);
      });
  };

  return (
    <div className="loginpage-container">
      <Form onSubmit={handleSubmit}>
        <Input
          type="email"
          label="Adresse E-mail"
          name="email"
          value={email}
          onChange={(event) => { setEmail(event.target.value); }}
        />

        <Input
          type="password"
          label="Mot de passe"
          name="password"
          value={password}
          onChange={(event) => { setPassword(event.target.value); }}
        />
          
        <Button type="submit">Se connecter</Button>
      </Form>
    </div>
  );
}

export default LoginPage;
