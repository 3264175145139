const labels = {
  webdev: 'Développeur web',
  tssr: 'Technicien Supérieur Systèmes et Réseaux',
  adminsys: 'Administrateur systèmes et réseaux',
  devops: 'Administrateur système DevOps',
  webdevjs: 'Développeur fullstack JS',
  alternance: 'Alternance',
  dwa: 'Développement web et accessibilité',
  socle: 'Socle développement web',
  soclejs: 'Socle fullstack JS',
  'spe-react': 'React.JS',
  'spe-symfony': 'Symfony',
  'spe-wordpress': 'WordPress',
  'spe-data': 'Data et API',
};

export default (slug) => labels[slug] || 'Formation inconnue';
