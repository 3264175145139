/*
 * Pacakge Import
 */
import React, { useMemo } from 'react';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';

import './styles.scss';

function Input({ label, ...restProps }) {
  const id = useMemo(() => (restProps.id ? restProps.id : uniqid()), [restProps.id]);
  return (
    <div className={`input-container input-container--${restProps.type}`}>
      {label && (
        <label htmlFor={id}>
          {label}
        </label>
      )}
      <input {...restProps} id={id} />
    </div>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
};

Input.defaultProps = {
  label: null,
  type: 'text',
  id: null,
};

export default Input;
