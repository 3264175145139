/*
 * Package Import
 */
import React from 'react';
import astronautImage from 'src/assets/images/common/astronaut.svg';

/*
 * Local Import
 */
import './styles.scss';

/*
 * Component
 */
function NotFound() {
  return (
    <div className="notFoundContainer">
      <img alt="Astronaut" src={astronautImage} />
      <div className="title">Houston, nous avons un problème...</div>
      <p>
        Il semblerait que nous n’arrivons pas à te trouver ¯\_(ツ)_/¯. <br /> Si
        le problème persiste, n’hésite pas à nous contacter à cette adresse{' '}
        <a className="link" href={`mailto:${process.env.CONTACT_ADDRESS}`}>
          <strong>{process.env.CONTACT_ADDRESS}</strong>
        </a>
      </p>
    </div>
  );
}

/*
 * Export
 */
export default NotFound;
