/*
 * Pacakge Import
 */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/Form/Button/index';

import './styles.scss';
import { Input } from 'src/components/Form';

function QuestionAnswers({ _id, label, answers, type, onValidateAnswer, onPassQuestion, image, responses }) {
  const [userAnswer, setUserAnswer] = useState([]);

  const validateQuestion = useCallback(() => {
    onValidateAnswer(userAnswer);
  }, [onValidateAnswer, userAnswer]);

  const passQuestion = useCallback(() => {
    onPassQuestion(userAnswer);
  }, [onPassQuestion, userAnswer]);

  useEffect(() => {
    setUserAnswer(responses);
  }, [_id, responses]);

  let AnswerComponent = null;
  if (type === 'text') {
    AnswerComponent = (
      <Input
        type="text"
        label="Réponse"
        placeholder="Ex: 12"
        value={userAnswer[0] || ''}
        name={_id}
        onChange={(event) => {
          setUserAnswer([event.target.value]);
        }}
      />
    );
  }
  else {
    AnswerComponent = answers.map((answer) => (
      <Input
        key={answer}
        type={type}
        label={answer}
        name={_id}
        checked={userAnswer.includes(answer)}
        onChange={(event) => {
          if (type === 'checkbox') {
            if (event.target.checked) {
              setUserAnswer([...userAnswer, answer]);
            }
            else {
              const newUserAnswer = [...userAnswer];
              newUserAnswer.splice(newUserAnswer.findIndex((userAnswerIt) => userAnswerIt === answer), 1);
              setUserAnswer(newUserAnswer);
            }
          }
          else if (type === 'radio') {
            setUserAnswer([answer]);
          }
          else setUserAnswer([event.target.value]);
        }}
      />
    ));
  }
  return (
    <div className="QuestionAnswers answer-container">
      {image ? <img className="QuestionAnswers__image" alt={label} src={image} /> : null}
      <div className={`QuestionAnswers__answers QuestionAnswers__answers--${type}`}>
        {AnswerComponent}
      </div>
      <div className="rituelpage-buttons">
        <Button color="secondary" onClick={passQuestion}>Je passe</Button>
        <Button onClick={validateQuestion}>Je valide</Button>
      </div>
    </div>
  );
}

QuestionAnswers.propTypes = {
  _id: PropTypes.string.isRequired,
  image: PropTypes.string,
  answers: PropTypes.array,
  responses: PropTypes.array,
  label: PropTypes.string,
  type: PropTypes.string,
  onValidateAnswer: PropTypes.func,
  onPassQuestion: PropTypes.func,
};

QuestionAnswers.defaultProps = {
  answers: [],
  responses: [],
  label: '',
  image: null,
  type: 'radio',
  onValidateAnswer: () => {},
  onPassQuestion: () => {},
};

export default QuestionAnswers;
