import PropTypes from 'prop-types';
import './styles.scss';

function Button({ children, onSubmit, color, type, disabled, ...restProps }) {
  const cssClassNames = color ? `Button Button--${color}` : 'Button';

  return (
    <button
      className={cssClassNames}
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={disabled ? () => {} : onSubmit}
      disabled={disabled}
      {...restProps}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  type: PropTypes.string,
};

Button.defaultProps = {
  onSubmit: () => {},
  color: null,
  disabled: false,
  type: 'button',
};
export default Button;
