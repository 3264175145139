/*
 * Package Import
 */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Button from 'src/components/Form/Button';
import ApiCaller from 'src/commons/ApiCaller';
import { useError } from 'src/commons/MessagesProvider';
import { dayjs, getGradeCategoryLabelFromSlug } from 'src/utils/index';
import Skeleton from 'react-loading-skeleton';

/*
 * Local Import
 */
import './styles.scss';

/*
 * Component
 */
function ResultRituel() {
  const showError = useError();
  
  const navigate = useNavigate();
  const { course } = useParams();

  const [loading, setLoading] = useState(false);
  const [rituelDateEnd, setRituelDateEnd] = useState(null);
  const [rituelDateBegin, setRituelDateBegin] = useState(null);
  const [rituelAdmissibility, setRituelAdmissibility] = useState({});
  const [rituelResult, setRituelResult] = useState({});

  useEffect(() => {
    setLoading(true);
    ApiCaller.makeRequest('GET', `/user/rituel/${course}`)
      .then(({ dateEnd, dateBegin, admissibility, result }) => {
        if (!dateEnd || !dateBegin) {
          navigate(`/start/${course}`);
        }
        setRituelDateEnd(dateEnd);
        setRituelDateBegin(dateBegin);
        setRituelAdmissibility(admissibility);
        setRituelResult(result);
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="ResultRituel">
        <h2>Dates</h2>
        <div className="ResultRituel__dates ResultRituel__box">
          <Skeleton count={4} baseColor="#078975" highlightColor="#0ac3a7" />
        </div>
        <h2>Score</h2>
        <div className="ResultRituel__score ResultRituel__box">
          <Skeleton count={4} baseColor="#078975" highlightColor="#0ac3a7" />
        </div>

        <h2>Décision</h2>
      
        <div className={classNames({
          ResultRituel__decision: true,
          ResultRituel__box: true,
        })}
        >
          <Skeleton count={4} baseColor="#078975" highlightColor="#0ac3a7" />
          <br />
          <Skeleton count={1} height={50} baseColor="#078975" highlightColor="#0ac3a7" />
        </div>
        <Link to="/courses">
          <Button>Revenir à la liste des rituels</Button>
        </Link>
      </div>
    );
  }

  const timeSpentDuration = rituelResult?.timeSpent && dayjs.duration(rituelResult.timeSpent, 'seconds');
  return (
    <div className="ResultRituel">
      <h2>Dates</h2>
      <div className="ResultRituel__dates ResultRituel__box">
        <div className="ResultRituel__date">
          {rituelDateBegin && <div className="ResultRituel__dateBegin">Tu as démarré ton rituel le <b>{dayjs(rituelDateBegin).format('LLL')}</b></div>}
        </div>
        <div className="ResultRituel__date">
          {rituelDateEnd && <div className="ResultRituel__dateEnd">Et tu l'a terminé le <b>{dayjs(rituelDateEnd).format('LLL')}</b></div>}
        </div>
        <div className="ResultRituel__date">
          {timeSpentDuration && <div className="ResultRituel__timeSpent">Au total tu as passé ton rituel en <b>{timeSpentDuration.format('H [heure(s)] mm [minute(s)] [et] ss [seconde(s)]')}</b></div>}
        </div>
      </div>
      <h2>Score</h2>
      {rituelResult && (
        <div className="ResultRituel__score ResultRituel__box">
          {rituelResult?.grades && (
            <ul>
              {Object.keys(rituelResult?.grades).map((grade) => (
                <li key={grade} className={`ResultRituel__grade grade grade--${grade}`}>
                  <span className="grade__title">{getGradeCategoryLabelFromSlug(grade)}</span>
                  <span className="grade__score">{Math.round(rituelResult.grades[grade])}%</span>
                </li>
              ))}
            </ul>
          )}
          <h3>Moyenne pondérée</h3>
          {rituelResult?.average !== undefined && <div className="ResultRituel__average">{rituelResult.average} %</div>}
        </div>
      )}

      <h2>Décision</h2>
      
      <div className={classNames({
        ResultRituel__decision: true,
        ResultRituel__box: true,
        'ResultRituel__decision--success': rituelAdmissibility?.decision === 'Admissible',
        'ResultRituel__decision--fail': rituelAdmissibility?.decision === 'Non-admissible',
      })}
      >
        <div className="decision">{rituelAdmissibility?.decision}</div>
        {rituelAdmissibility?.reasons && rituelAdmissibility?.reasons.length && (
        <div className="reasons">
          <ul>
            {rituelAdmissibility?.reasons.map((reason) => (
              <li key={reason}>{reason}</li>
            ))}
          </ul>
        </div>
        ) || null }
      </div>
      <Link to="/courses">
        <Button>Revenir à la liste des rituels</Button>
      </Link>
    </div>
  );
}

/*
 * Export
 */
export default ResultRituel;
