/*
 * Package Import
 */
import React, { useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

/*
 * Local Import
 */
import ApiCaller from 'src/commons/ApiCaller';
import { withMessages } from 'src/commons/MessagesProvider';
import { identitySelector } from '@recoil/auth';
import PropTypes from 'prop-types';
  
function AuthProvider({ children, showError }) {
  const [identity, setIdentity] = useRecoilState(identitySelector);
  const location = useLocation();

  useEffect(() => {
    const logoutUser = () => {
      showError(
        'Vous avez été déconnecté, vous allez être redirigé dans 2 secondes',
        2000,
      );
      setTimeout(setIdentity.bind(this, null), 2000);
    };

    ApiCaller.eventEmitter.on('error_401', logoutUser);
    return () => {
      ApiCaller.eventEmitter.off('error_401', logoutUser);
    };
  }, []);

  if (!identity?.sub && location.pathname !== '/login') {
    return <Navigate to="/login" replace />;
  }

  return children;
}

AuthProvider.propTypes = {
  children: PropTypes.any,
  showError: PropTypes.func,
};

export default withMessages(AuthProvider);
