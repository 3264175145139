import PropTypes from 'prop-types';
import dayjs from 'src/utils/dayjs';
import { getCourseLabelFromSlug, getGradeCategoryLabelFromSlug } from 'src/utils';
import Card from 'src/components/Card';

import './styles.scss';

function RituelCard({ rituel }) {
  return (
    <Card
      title={getCourseLabelFromSlug(rituel.course)}
      link={rituel?.dateEnd ? `/result/${rituel.course}` : `/start/${rituel.course}`}
      className="Card--rituel"
      linkText={rituel?.dateEnd ? 'Voir le détail' : 'Démarrer le rituel'}
    >
      {rituel?.dateBegin && <div className="Card__dateBegin">Démarré le <b>{dayjs(rituel.dateBegin).format('LLL')}</b></div>}
      {rituel?.dateEnd && <div className="Card__dateEnd">Terminé le <b>{dayjs(rituel.dateEnd).format('LLL')}</b></div>}
      <div className="Card__score">
        {rituel?.admissibility?.decision !== undefined && <div className="Card__decision">{rituel.admissibility.decision}</div>}
      </div>
    </Card>
  );
}

RituelCard.propTypes = {
  rituel: PropTypes.shape({
    course: PropTypes.string,
    dateBegin: PropTypes.string,
    dateEnd: PropTypes.string,
    result: PropTypes.shape({
      grades: PropTypes.object,
      average: PropTypes.number,
    }),
    admissibility: PropTypes.shape({
      decision: PropTypes.string,
    }),
  }),
};

RituelCard.defaultProps = {
  rituel: {},
};

export default RituelCard;
