import { dayjs } from 'src/utils/index';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ApiCaller from 'src/commons/ApiCaller';
import { useError, useWarning } from 'src/commons/MessagesProvider';
import Button from 'src/components/Form/Button';
import Skeleton from 'react-loading-skeleton';
import QuestionAnswers from './components/QuestionAnswers';
import Steps from './components/Steps';
import './styles.scss';

function RituelPage() {
  const showError = useError();
  const showWarning = useWarning();
  const navigate = useNavigate();

  const { course } = useParams();

  const [loading, setLoading] = useState(false);
  const [elapsed, setElapsed] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [finishAsked, setFinishAsked] = useState(false);
  const [questionsPassed, setQuestionsPassed] = useState(JSON.parse(localStorage.getItem(`${course}_passed`)) || {});
  const [answers, setAnswers] = useState({});
  const [currentQuestionID, setCurrentQuestionId] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const changeQuestionByIndex = useCallback((questionIndex, apiQuestions = null) => {
    if (finishAsked) {
      setFinishAsked(false);
    }
    const questionsArray = apiQuestions || questions;
    if (questionsArray?.[questionIndex]) {
      setCurrentQuestionIndex(questionIndex);
    }
  }, [questions, currentQuestionIndex, finishAsked]);

  const changeToNextQuestion = useCallback((apiQuestions = null, apiAnswers = null) => {
    const questionsArray = apiQuestions || questions;
    const answersObject = apiAnswers || answers;

    const questionIndex = questionsArray.findIndex(
      (question, index) => index >= currentQuestionIndex && !answersObject[question._id] && !questionsPassed[question._id],
    );
      
    if (questionIndex >= 0) {
      changeQuestionByIndex(questionIndex, apiQuestions);
    }
    else {
      setFinishAsked(true);
    }
  }, [questions, answers, questionsPassed, currentQuestionIndex]);

  useEffect(() => {
    localStorage.setItem(`${course}_passed`, JSON.stringify(questionsPassed));
    changeToNextQuestion();
  }, [questionsPassed]);
  
  function updateElapsed(start) {
    const diff = dayjs.duration(dayjs().diff(start));
    setElapsed(diff.humanize());
    setTimeout(() => { updateElapsed(start); }, 1000);
  }

  useEffect(() => {
    setLoading(true);
    ApiCaller.makeRequest('POST', '/rituel/start', { course })
      .catch((error) => {
        if (error.status === 400) {
          return ApiCaller.makeRequest('GET', `/user/rituel/${course}`);
        }
        throw error;
      })
      .then(({ dateEnd, dateBegin: apiDateBegin, questions: apiQuestions, responses }) => {
        // Si on a une date de fin, on ne va pas sur ce rituel
        if (dateEnd) {
          navigate(`/result/${course}`);
        }
        setAnswers(responses);
        updateElapsed(apiDateBegin);
        setQuestions(apiQuestions);
        changeToNextQuestion(apiQuestions, responses);
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const newQuestionsPassed = { ...questionsPassed };
    Object.keys(answers).forEach((questionId) => {
      delete newQuestionsPassed[questionId];
    });
    setQuestionsPassed(newQuestionsPassed);
  }, [answers]);
  
  const validateRituel = useCallback(() => {
    ApiCaller.makeRequest('POST', '/rituel/end', {
      course,
      responses: answers,
    }).then(() => {
      navigate(`/result/${course}`);
    }).catch((error) => {
      showError(error.message);
    });
  }, [answers]);

  const saveAnswer = useCallback(
    (questionId, response) => {
      ApiCaller.makeRequest('PUT', '/rituel/response', {
        course,
        response,
        questionId,
      }).then(({ responses }) => {
        setAnswers(responses);
        changeToNextQuestion(questions, responses);
      }).catch((error) => {
        showError(error.message);
      });
    }, [questions, currentQuestionIndex]);

  useEffect(() => {
    setCurrentQuestionId(questions?.[currentQuestionIndex]?._id);
    try {
      // C'est pas beau mais c'est la vie
      document.getElementsByClassName('mainContent')[0].scrollTop = 0;
    }
    catch (error) { console.error(error); }
  }, [questions, currentQuestionIndex]);

  const currentQuestion = useMemo(() => questions.find((question) => question._id === currentQuestionID), [questions, currentQuestionID]);
  
  const validateAnswer = useCallback((answer) => {
    if (!answer || answer?.length === 0) {
      showWarning('Tu dois répondre à la question pour la valider, sinon tu peux la passer');
    }
    else {
      saveAnswer(currentQuestionID, answer);
    }
  }, [currentQuestionID]);

  const passQuestion = useCallback((answer) => {
    if (currentQuestionIndex + 1 === questions?.length) {
      setFinishAsked(true);
    }
    else {
      setQuestionsPassed({ ...questionsPassed, [currentQuestionID]: answer });
      changeToNextQuestion();
    }
  }, [currentQuestionID, currentQuestionIndex, questionsPassed]);
  
  if (loading) {
    return (
      <div className="rituelpage-loading">
        <div className="bloc bloc1">
          <Skeleton count={2} />
        </div>
      
        <div className="bloc bloc2">
          <Skeleton count={5} />
        </div>

        <div className="bloc bloc3">
          <Skeleton count={3} />
        </div>
          
        <div className="bloc bloc4">
          <Skeleton count={1} height={50} />
        </div>
      </div>
    );
  }
  if (!questions.length || !currentQuestion) {
    return (
      <div className="rituelpage-container">
        <h1 className="rituelpage-title">Aucune question à afficher ... C'est pas normal, tu devrais contacter le service technique ...</h1>
      </div>
    );
  }

  const nbPassedQuestions = Object.keys(questionsPassed).length;
  const nbAnswerdQuestions = Object.keys(answers).length;
  const nbForgotQuestions = (questions?.length || 0) - (nbPassedQuestions + nbAnswerdQuestions);

  return (
    <div className="rituelpage-container">
      {elapsed && (
        <div className="rituelpage-elapsed">
          Rituel démarré il y a {elapsed}
        </div>
      )}

      <div className="rituelpage-question">

        {!finishAsked ? (
          <>
            <h1 className="rituelpage-title">{currentQuestion?.label || 'Impossible d\'afficher l\'intitulé de la question'}</h1>
            <QuestionAnswers
              {...currentQuestion}
              responses={answers[currentQuestionID]}
              onValidateAnswer={validateAnswer}
              onPassQuestion={passQuestion}
            />
          </>
        ) : (
          <div className="QuestionAnswers answer-container">
            <h1 className="rituelpage-title">
              Tu es sur le point de valider ton rituel
              {nbForgotQuestions && ` cependant il reste ${nbForgotQuestions} question(s) sans réponse` || null}
              <br />
              Es-tu vraiment sûr(e) ?
            </h1>
            <Button onClick={validateRituel}>Oui je suis sûr(e)</Button>
            <Button
              color="secondary"
              onClick={() => {
                setFinishAsked(false);
              }}
            >
              Non je continue
            </Button>
          </div>
        )}
        
      </div>

      <Steps
        questions={questions}
        currentQuestionIndex={currentQuestionIndex}
        questionsPassed={questionsPassed}
        answers={answers}
        onStepClick={(step) => changeQuestionByIndex(step)}
      />
    </div>
  );
}

export default RituelPage;
