import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss';

function Card({ children, className, title, link, linkText }) {
  const CardContent = (
    <article className={classNames('Card', className)}>
      {title && <h3 className="Card__title">{title}</h3>}
      <div className="Card__content">{children}</div>
      {linkText && link && <Link to={link}><div className="Card__footer">{linkText}</div></Link>}
    </article>
  );
  return CardContent;
}

Card.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

Card.defaultProps = {
  title: null,
  link: null,
  className: {},
};
export default Card;
